exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-karin-tsx": () => import("./../../../src/pages/about-karin.tsx" /* webpackChunkName: "component---src-pages-about-karin-tsx" */),
  "component---src-pages-coaching-tsx": () => import("./../../../src/pages/coaching.tsx" /* webpackChunkName: "component---src-pages-coaching-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-how-does-it-work-tsx": () => import("./../../../src/pages/how-does-it-work.tsx" /* webpackChunkName: "component---src-pages-how-does-it-work-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-policies-tsx": () => import("./../../../src/pages/policies.tsx" /* webpackChunkName: "component---src-pages-policies-tsx" */)
}

